import styled from '@emotion/styled';

import { bodyLarge, bodyMedium, subheadingSmall } from '@/styles/typography';

import type { componentSpacingOptions, Spacing } from '@/types/measures';
import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

export interface BulletPointsProps {
  pointColour?:
    | '--decorative-ultraviolet'
    | '--icon-inverse-warm-base'
    | '--icon-warm-base';
  paragraphFontSize?: 'sm' | 'md' | 'body-md'; // 'md' might be for a hero with larger text
  iconSize?: number;
  children?: ReactNode;
  className?: string;
  bulletPointStyle?: string;
  paddingBetween?: Spacing | componentSpacingOptions;
}

const BulletPoints = ({
  pointColour = '--decorative-ultraviolet',
  paragraphFontSize = 'sm',
  iconSize = 20,
  children,
  className,
  bulletPointStyle = 'check',
  paddingBetween = '--spacing-xx-small',
}: BulletPointsProps) => {
  return (
    <BulletPointsContainer
      className={className}
      pointColour={pointColour}
      paragraphFontVariable={paragraphFontVariable(paragraphFontSize)}
      bulletPointStyle={
        bulletPointStyle === 'check'
          ? '/images/bullet-tick.svg'
          : '/images/hexagon.svg'
      }
      paddingBetween={paddingBetween}
      iconSize={iconSize}
    >
      {children}
    </BulletPointsContainer>
  );
};

const paragraphFontVariable = (
  paragraphFontSize: BulletPointsProps['paragraphFontSize'],
) => {
  switch (paragraphFontSize) {
    case 'body-md':
      return bodyMedium;
    case 'md':
      return subheadingSmall;
    case 'sm':
    default:
      return bodyLarge;
  }
};

const BulletPointsContainer = styled.div<{
  pointColour:
    | '--decorative-ultraviolet'
    | '--icon-inverse-warm-base'
    | '--icon-warm-base';
  paragraphFontVariable: SerializedStyles;
  bulletPointStyle: string;
  paddingBetween: Spacing | componentSpacingOptions;
  iconSize: number;
}>`
  ul {
    li {
      &::before {
        background-color: ${({ pointColour }) => `var(${pointColour})`};
        -webkit-mask: url(${({ bulletPointStyle }) => bulletPointStyle})
          no-repeat center/ 18px;
        mask: url(${({ bulletPointStyle }) => bulletPointStyle}) no-repeat
          center/ 18px;
      }
    }
  }
  ul,
  ol {
    li {
      display: flex;
      margin-left: 0;

      &::before {
        content: '';
        -webkit-mask-size: cover;
        mask-size: cover;
        margin-right: var(--spacing-small);
        background-color: ${({ pointColour }) => `var(${pointColour})`};
        -webkit-mask: url(${({ bulletPointStyle }) => bulletPointStyle})
          no-repeat center/ ${({ iconSize }) => `${iconSize}px`};
        mask: url(${({ bulletPointStyle }) => bulletPointStyle}) no-repeat
          center/ ${({ iconSize }) => `${iconSize}px`};
        width: ${({ iconSize }) => `${iconSize}px`};
        height: ${({ iconSize }) => `${iconSize}px`};
        min-width: ${({ iconSize }) => `${iconSize}px`};
        position: relative;
        top: 0;
        margin-right: var(--space-component-md);
      }

      &:not(:last-child) {
        padding-bottom: ${({ paddingBetween }) => `var(${paddingBetween})`};
      }

      &::before {
        top: ${({ iconSize }) => (iconSize > 16 ? '3px' : '4px')};
      }
    }

    a {
      font-size: inherit;
    }

    b {
      font-weight: var(--font-weight-medium);
    }

    span,
    li {
      ${({ paragraphFontVariable }) => paragraphFontVariable};
    }
  }
`;

export { BulletPoints };
