import { orderedBreakpointKeys, screen } from '@/components/common/breakpoints';

import type {
  BadgeSizes,
  ResponsiveSet,
} from '@/components/common/Badge/Badge';

const singleBadgeStyle = (size: BadgeSizes) => {
  switch (size) {
    case 'lg':
      return `
          padding: 7px 12px;
        `;
    case 'md':
      return `
          font-size: var(--font-size-2);
          padding: 7px 10px 6px;
        `;
    case 'sm':
      return `
          font-size: var(--font-size-1);
          padding: 6px 8px 5px;
        `;
    default:
      return 'padding: 7px 12px;';
  }
};

export const getBadgeSizesStyles = (size: BadgeSizes | ResponsiveSet) => {
  if (typeof size == 'string') {
    return singleBadgeStyle(size);
  }
  const styles = [];

  orderedBreakpointKeys.forEach((breakpoint) => {
    if (Object.keys(size).includes(breakpoint)) {
      const breakpointSize = size[breakpoint] as BadgeSizes;
      const style = singleBadgeStyle(breakpointSize);

      styles.push(`
        ${screen[breakpoint]} {
          ${style}
        }
      `);
    }
  });

  return styles.join(' ');
};

export const getBadgeMarginStyles = (margin: string | ResponsiveSet) => {
  if (typeof margin == 'string') {
    return `margin: ${margin}`;
  }
  const styles = [];

  orderedBreakpointKeys.forEach((breakpoint) => {
    if (Object.keys(margin).includes(breakpoint)) {
      const breakpointMargin = margin[breakpoint];

      styles.push(`
        ${screen[breakpoint]} {
          margin: ${breakpointMargin}
        }
      `);
    }
  });
  return styles.join(' ');
};
