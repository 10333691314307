import dynamic from 'next/dynamic';

const Add = dynamic(() => import(`@/assets/icons/Add.svg`));
const AlertCircle = dynamic(() => import(`@/assets/icons/AlertCircle.svg`));
const ArrowUp = dynamic(() => import(`@/assets/icons/arrow-up.svg`));
const ArrowDown = dynamic(() => import(`@/assets/icons/arrow-down.svg`));
const ArrowLeft = dynamic(() => import(`@/assets/icons/arrow-left.svg`));
const ArrowRight = dynamic(() => import(`@/assets/icons/arrow-right.svg`));
const ArrowCircleRight = dynamic(
  () => import(`@/assets/icons/ArrowCircleRight.svg`),
);
const Calendar = dynamic(() => import(`@/assets/icons/Calendar.svg`));
const Check = dynamic(() => import(`@/assets/icons/Check.svg`));
const CheckCircle = dynamic(() => import(`@/assets/icons/CheckCircle.svg`));
const ChevronDown = dynamic(() => import(`@/assets/icons/chevron-down.svg`));
const ChevronRight = dynamic(() => import(`@/assets/icons/chevron-right.svg`));
const ChevronLeft = dynamic(() => import(`@/assets/icons/chevron-left.svg`));
const ChevronUp = dynamic(() => import(`@/assets/icons/chevron-up.svg`));
const Close = dynamic(() => import(`@/assets/icons/close.svg`));
const Email = dynamic(() => import(`@/assets/icons/Email.svg`));
const Hexagon = dynamic(() => import(`@/assets/icons/Hexagon.svg`));
const House = dynamic(() => import(`@/assets/icons/House.svg`));
const InfoCircle = dynamic(() => import(`@/assets/icons/InfoCircle.svg`));
const LinkedIn = dynamic(() => import(`@/assets/icons/LinkedIn.svg`));
const LocationPin = dynamic(() => import(`@/assets/icons/LocationPin.svg`));
const NavigationMenu = dynamic(() => import(`@/assets/icons/menu.svg`));
const Play = dynamic(() => import(`@/assets/icons/Play.svg`));
const PlayCircle = dynamic(() => import(`@/assets/icons/PlayCircle.svg`));
const Search = dynamic(() => import(`@/assets/icons/Search.svg`));
const Timer = dynamic(() => import(`@/assets/icons/Timer.svg`));
const Plus = dynamic(() => import(`@/assets/icons/Plus.svg`));
const Equals = dynamic(() => import(`@/assets/icons/Equals.svg`));

// CoursesIcons
const DataStack = dynamic(
  () => import(`@/assets/icons/Programmes/DataStack.svg`),
);
const CalendarNew = dynamic(
  () => import(`@/assets/icons/Programmes/CalendarNew.svg`),
);
const Career1 = dynamic(() => import(`@/assets/icons/Programmes/Career1.svg`));
const Career2 = dynamic(() => import(`@/assets/icons/Programmes/Career2.svg`));
const Challenge = dynamic(
  () => import(`@/assets/icons/Programmes/Challenge.svg`),
);
const DataModel = dynamic(
  () => import(`@/assets/icons/Programmes/DataModel.svg`),
);
const Presentation = dynamic(
  () => import(`@/assets/icons/Programmes/Presentation.svg`),
);
const DataGraphs = dynamic(
  () => import(`@/assets/icons/Programmes/DataGraphs.svg`),
);
const Wallet = dynamic(() => import(`@/assets/icons/Programmes/Wallet.svg`));
const Sparkle = dynamic(() => import(`@/assets/icons/Programmes/Sparkle.svg`));
const Coach = dynamic(() => import(`@/assets/icons/Programmes/Coach.svg`));
const Certificate = dynamic(
  () => import(`@/assets/icons/Programmes/Certificate.svg`),
);
const Learning1 = dynamic(
  () => import(`@/assets/icons/Programmes/Learning1.svg`),
);
const Learning2 = dynamic(
  () => import(`@/assets/icons/Programmes/Learning2.svg`),
);
const Conversation1 = dynamic(
  () => import(`@/assets/icons/Programmes/Conversation1.svg`),
);
const Conversation2 = dynamic(
  () => import(`@/assets/icons/Programmes/Conversation2.svg`),
);
const Work = dynamic(() => import(`@/assets/icons/Programmes/Work.svg`));
const PaperEdit = dynamic(
  () => import(`@/assets/icons/Programmes/PaperEdit.svg`),
);
const Network = dynamic(() => import(`@/assets/icons/Programmes/Network.svg`));
const Measure = dynamic(() => import(`@/assets/icons/Programmes/Measure.svg`));
const LadderTrophy = dynamic(
  () => import(`@/assets/icons/Programmes/LadderTrophy.svg`),
);
const GiveTime = dynamic(
  () => import(`@/assets/icons/Programmes/GiveTime.svg`),
);
const DataShare = dynamic(
  () => import(`@/assets/icons/Programmes/DataShare.svg`),
);
const UserAccount = dynamic(
  () => import(`@/assets/icons/Programmes/UserAccount.svg`),
);
const ProfileComplete = dynamic(
  () => import(`@/assets/icons/Programmes/ProfileComplete.svg`),
);
const Checklist = dynamic(
  () => import(`@/assets/icons/Programmes/Checklist.svg`),
);
const Cog = dynamic(() => import(`@/assets/icons/Programmes/Cog.svg`));
const Automate = dynamic(
  () => import(`@/assets/icons/Programmes/Automate.svg`),
);
const Leader = dynamic(() => import(`@/assets/icons/Programmes/Leader.svg`));
const Performance = dynamic(
  () => import(`@/assets/icons/Programmes/Performance.svg`),
);
const GiveMoney = dynamic(
  () => import(`@/assets/icons/Programmes/GiveMoney.svg`),
);
const Clock = dynamic(() => import(`@/assets/icons/Programmes/Clock.svg`));
const WindowData = dynamic(
  () => import(`@/assets/icons/Programmes/WindowData.svg`),
);
const WindowCode = dynamic(
  () => import(`@/assets/icons/Programmes/WindowCode.svg`),
);
const Promote = dynamic(() => import(`@/assets/icons/Programmes/Promote.svg`));
const Approve = dynamic(() => import(`@/assets/icons/Programmes/Approve.svg`));

export const icons = {
  Add,
  AlertCircle,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  Calendar,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  Close,
  Email,
  Hexagon,
  House,
  InfoCircle,
  DataStack,
  LinkedIn,
  LocationPin,
  NavigationMenu,
  Play,
  PlayCircle,
  Search,
  Timer,
  Plus,
  Equals,
  CalendarNew,
  Career1,
  Career2,
  Challenge,
  DataModel,
  Presentation,
  DataGraphs,
  Wallet,
  Sparkle,
  Coach,
  Certificate,
  Learning1,
  Learning2,
  Conversation1,
  Conversation2,
  Work,
  PaperEdit,
  Network,
  Measure,
  LadderTrophy,
  GiveTime,
  DataShare,
  UserAccount,
  ProfileComplete,
  Checklist,
  Cog,
  Automate,
  Leader,
  Performance,
  GiveMoney,
  Clock,
  WindowData,
  WindowCode,
  Promote,
  Approve,
};
