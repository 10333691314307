import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useKeyboardEvent, useMeasure } from '@react-hookz/web';
import { m } from 'framer-motion';
import Link from 'next/link';
import FocusLock from 'react-focus-lock';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { zIndex } from '@/components/common/z-index';
import { MultiverseLogo } from '@/components/layout/MultiverseLogo';
import {
  DesktopNavLinks,
  mobileBreakpoint,
} from '@/components/layout/NewNavigationBar/DesktopNavLinks';
import { SubNavSplitLayout } from '@/components/layout/NewNavigationBar/SubNavigation/SubNavSplitLayout';
import { SubNavStandardLayout } from '@/components/layout/NewNavigationBar/SubNavigation/SubNavStandardLayout';
import { blockUrlWithAudience } from '@/lib/utils';

import type { Navigation_exType } from '@/types/shared';

interface DesktopNavProps {
  navigation: Navigation_exType;
  isAudienceSwitcherEnabled?: boolean;
  isSticky?: boolean;
}
const transitionDuration = 0.3;

export const DesktopNav_ex = ({
  navigation,
  isAudienceSwitcherEnabled,
  isSticky,
}: DesktopNavProps) => {
  const [activeItem, setActiveItem] = useState(null);
  const [subNavHeight, setSubNavHeight] = useState(0);
  const [navHeightOffset, setNavHeightOffset] = useState(0);
  const [navBarDimensions, globalNavRef] = useMeasure<HTMLDivElement>();

  const isActive = Boolean(activeItem);
  const activeVariant = isActive ? 'open' : 'close';

  const closeNav = () => {
    setActiveItem(null);
    setSubNavHeight(0);
  };
  useKeyboardEvent('Escape', () => {
    closeNav();
  });

  const navMouseEnter = (e) => {
    const navHeight = e.target.getBoundingClientRect().height;
    const offsetTop = e.target.getBoundingClientRect().top;
    setNavHeightOffset(navHeight + offsetTop);
  };

  const [primaryCTA, secondaryCTA] = navigation.ctaList || [];

  const subNavContainerHeight = `calc(0px + ${subNavHeight}px + ${navBarDimensions?.height}px + ${navHeightOffset}px)`;

  return (
    <GlobalNav
      ref={globalNavRef}
      animate={activeVariant}
      transition={{ duration: transitionDuration }}
      initial="close"
      isSticky={isSticky}
      variants={{
        open: {
          background: 'rgba(255, 255, 255, 1)',
        },
        close: {
          background: 'rgba(255, 255, 255, 0.8)',
        },
      }}
    >
      <FocusLock disabled={!isActive} autoFocus={false}>
        <Container>
          <LogoLink
            href="/"
            aria-label="Multiverse home"
            tabIndex={isActive ? -1 : 0}
          >
            <MultiverseLogo version="primary" />
          </LogoLink>
          <Nav
            aria-label="main menu"
            id="subnavigation-list"
            animate={activeVariant}
            initial="close"
          >
            <DesktopNavLinks
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              items={navigation?.navItems}
              navMouseEnter={navMouseEnter}
              navigationAudience={navigation.audience}
              isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
            />
          </Nav>

          <CtaContainer>
            {!!secondaryCTA?.buttonText && (
              <Button
                variant="flat-light"
                href={blockUrlWithAudience(
                  secondaryCTA.buttonUrl,
                  navigation.audience,
                  navigation.audience,
                  secondaryCTA.noAudienceToggle,
                  isAudienceSwitcherEnabled,
                )}
                aria-label={secondaryCTA?.buttonAccessibleLabel}
                tabIndex={isActive ? -1 : 0}
                size="md"
              >
                {secondaryCTA.buttonText}
              </Button>
            )}
            {!!primaryCTA?.buttonText && (
              <Button
                variant="solid-light"
                href={blockUrlWithAudience(
                  primaryCTA.buttonUrl,
                  navigation.audience,
                  navigation.audience,
                  primaryCTA.noAudienceToggle,
                  isAudienceSwitcherEnabled,
                )}
                aria-label={primaryCTA?.buttonAccessibleLabel}
                tabIndex={isActive ? -1 : 0}
                icon="ArrowRight"
                iconPosition="right"
                size="md"
              >
                {primaryCTA.buttonText}
              </Button>
            )}
          </CtaContainer>
          <SubNavPosition>
            <SubNavContainer
              animate={activeVariant}
              initial="close"
              variants={{
                open: {
                  height: subNavContainerHeight,
                  maxHeight: `calc(100vh  - 48px - ${navHeightOffset}px)`,
                },
                close: { height: '0px' },
              }}
              transition={{
                duration: transitionDuration,
                delay: 0.1,
              }}
              onPointerLeave={() => closeNav()}
            >
              <SubNavSizer>
                {navigation?.navItems?.map((item) => {
                  if (item._type !== 'navDirectLink')
                    return (
                      <SubNavPanel
                        key={item._key}
                        isOpen={activeItem === item._key}
                        activeItem={activeItem}
                        onHeightChange={(height) => {
                          if (activeItem === item._key) setSubNavHeight(height);
                        }}
                      >
                        {item?.layoutMode === 'standard' && (
                          <SubNavStandardLayout
                            key={item._key}
                            navItem={item}
                            navigationAudience={navigation.audience}
                            isAudienceSwitcherEnabled={
                              isAudienceSwitcherEnabled
                            }
                          />
                        )}
                        {item?.layoutMode === 'split' && (
                          <SubNavSplitLayout
                            key={item._key}
                            navItem={item}
                            navigationAudience={navigation.audience}
                            isAudienceSwitcherEnabled={
                              isAudienceSwitcherEnabled
                            }
                          />
                        )}
                      </SubNavPanel>
                    );
                })}
              </SubNavSizer>
            </SubNavContainer>
            <BackgroundOverlay
              animate={isActive ? 'show' : 'hide'}
              variants={{
                show: {
                  display: 'block',
                  opacity: 1,
                },
                hide: {
                  opacity: 0,
                  transitionEnd: { display: 'none' },
                },
              }}
            />
          </SubNavPosition>
        </Container>
      </FocusLock>
    </GlobalNav>
  );
};

export const SubNavPanel = ({
  onHeightChange,
  isOpen,
  children,
  activeItem,
}) => {
  // activeItem prop is used to allow no visible content fadeout when you switch from an open nav item to no nav item open
  // a 2 second delay allows the nav container height to reduce to 0 before you can see the an opacity transition
  // if you are switching from a nav item to a different nav item, there will be no delay. The content will transition from old to new with opacity

  const [subNavPanelDimensions, subNavItemRef] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (subNavPanelDimensions && isOpen && onHeightChange) {
      onHeightChange(subNavPanelDimensions.height);
    }
  }, [isOpen, subNavPanelDimensions, onHeightChange]);

  return (
    <SubnavItem
      ref={subNavItemRef}
      initial="hidden"
      animate={isOpen ? 'show' : 'hidden'}
      variants={{
        show: {
          display: 'flex',
          opacity: 1,
        },
        hidden: {
          opacity: 0,
          position: 'absolute',
          transitionEnd: {
            display: 'none',
          },
        },
      }}
      transition={{
        duration: transitionDuration,
        delay: !activeItem ? 0.5 : 0,
      }}
    >
      {children}
    </SubnavItem>
  );
};

const GlobalNav = styled(m.header)<{ isSticky: boolean }>`
  display: none;

  @media (min-width: ${mobileBreakpoint}px) {
    display: block;
    position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
    top: 0;

    margin: 0 auto;
    min-width: var(--mobile-min-width);
    width: 100%;
    padding: var(--space-block-sm) 0;
    ${screen.lg} {
      padding-left: 80px;
      padding-right: 80px;
    }
    z-index: ${zIndex.navBar};
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(24px);
  }
`;

const Container = styled(m.div)`
  display: flex;
  gap: var(--space-component-md);
  align-items: center;

  max-width: var(--navigation_ex-desktop-max-width);

  margin: auto;
  z-index: ${zIndex.desktopNav};
`;

const Nav = styled(m.nav)`
  position: relative;

  display: flex;
  align-items: center;

  flex: 1;
`;

const LogoLink = styled(Link)`
  display: block;
  color: var(--decorative-ultraviolet);

  max-width: var(--spacing-1800);
  width: max-content;
`;

const BackgroundOverlay = styled(m.div)`
  background: rgba(0, 0, 0, 0.4);

  width: 100%;
  position: relative;
  pointer-events: none;
  top: 0;
  z-index: -1;
  height: 100vh;
  opacity: 0;
  display: none;
`;
const SubNavContainer = styled(m.div)`
  overflow: hidden;
  overflow-y: scroll;
  background: white;
  margin-top: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${screen.lg} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const SubNavSizer = styled(m.div)`
  will-change: height;
  position: relative;
  max-width: var(--navigation_ex-desktop-max-width);
  width: 100%;
`;

const SubNavPosition = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  gap: 12px;
`;

const SubnavItem = styled(m.div)`
  padding-top: var(--space-block-lg);
  top: 0;
  left: 0;
  width: 100%;
`;
