/* eslint-disable @next/next/no-sync-scripts */
import Script from 'next/script';

import { getDYSectionId } from '@/lib/dynamicYield';

// Scripts

export const ProductionScripts = () => {
  const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET;
  if (process.env.NODE_ENV === 'production') {
    // Directly return the elements, KetchCookieConsent now decides the script itself
    if (dataset?.includes('prod')) {
      return (
        <>
          <KetchCookieConsent dataset={dataset} />
          <MicrosoftClarity />
        </>
      );
    }
    return <KetchCookieConsent dataset={dataset} />;
  }

  return null;
};

export const KetchCookieConsent = ({ dataset }) => {
  const scriptBaseUrl = dataset?.includes('prod')
    ? 'https://global.ketchcdn.com/web/v2/config/multiverse/'
    : 'https://global.ketchcdn.com/web/v2/config/multiverse_test/';

  const notifyConsentChange = (host) => {
    host.on('consent', (consent) => {
      const consentEvent = new CustomEvent('cookieConsentChange', {
        detail: {
          hasConsentedBehavioural: Boolean(
            consent?.purposes?.functionality_cookies ||
              consent?.purposes?.functionality_cookies_,
          ),
        },
      });
      window.dispatchEvent(consentEvent);
    });

    host.on('hideExperience', (reason) => {
      if (reason === 'setConsent') {
        const consentClosedEvent = new CustomEvent('cookieConsentClosed', {
          detail: {
            reload: true,
          },
        });
        window.dispatchEvent(consentClosedEvent);
      }
    });
  };

  return (
    <Script id="ketch-cookie-consent-script" type="text/javascript">
      {`!(function () {
      (window.semaphore = window.semaphore || []),
      (window.ketch = function () {
        window.semaphore.push(arguments);
      });
      var e = new URLSearchParams(document.location.search),
          o = e.has('property') ? e.get('property') : 'website_smart_tag',
          n = document.createElement('script');
      n.type = 'text/javascript';
      n.src = '${scriptBaseUrl}'.concat(o, '/boot.js');
      n.defer = n.async = true;
      document.getElementsByTagName('head')[0].appendChild(n);
      window.ketch('registerPlugin', ${notifyConsentChange});
    })();`}
    </Script>
  );
};

export const INTERCOM_APP_ID = 'tvl27sse';

export const Intercom = () => {
  return (
    <Script id="intercom-script" type="text/javascript">
      {`(function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = "https://widget.intercom.io/widget/${INTERCOM_APP_ID}";
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();`}
    </Script>
  );
};

const MicrosoftClarity = () => (
  <Script id="clarity-script" type="text/javascript">
    {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "44qgf2zuoa");`}
  </Script>
);

export const SixSenseDataProxy = () => {
  return (
    <script
      id="six-sense-data-proxy-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || new Proxy([], {
            set: (obj, prop, value) => {
              if (prop !== 'length') {
                const pushEvent = new CustomEvent('dataLayerPush', {
                  detail: value
                });
                window.dispatchEvent(pushEvent);
              }
              return Reflect.set(obj, prop, value);
            }
          });
        `,
      }}
    />
  );
};

// Preload

export const LoadDynamicYield = () => {
  return (
    <>
      <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
      <link rel="preconnect" href="//st-eu.dynamicyield.com" />
      <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//cdn-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//st-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//rcom-eu.dynamicyield.com" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.DY = window.DY || {};
          window.DYO = window.DYO || {};`,
        }}
      />
      <script
        id="dy-api-dynamic-script"
        type="text/javascript"
        key="dy-api-dynamic-script"
        src={`//cdn-eu.dynamicyield.com/api/${getDYSectionId()}/api_dynamic.js`}
      />
      <script
        id="dy-api-static-script"
        type="text/javascript"
        key="dy-api-static-script"
        src={`//cdn-eu.dynamicyield.com/api/${getDYSectionId()}/api_static.js`}
      />
    </>
  );
};
