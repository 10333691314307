/* eslint-disable @next/next/no-img-element */
import styled from '@emotion/styled';
import Link from 'next/link';

import { Badge } from '@/components/common/Badge';
import { Divider } from '@/components/common/Divider';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { PortableText } from '@/components/common/utils';
import { Stack } from '@/components/layout/Stack';
import { getProgrammeCardDecorativePosition } from '@/components/page/courses/CourseCard/utils';
import {
  getBackgroundColor,
  getDecorativeImage,
  getTokenColor,
} from '@/components/page/courses/ProgrammeHero/helper';
import { blockUrlWithAudience } from '@/lib/utils';
import { bodyMedium, bodySmall, labelMedium } from '@/styles/typography';

import type { DecorativeColourType, SurfaceColourType } from '@/types/colours';
import type {
  SanityAudienceType,
  SanityProgrammeB2CType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface CourseCardProps extends BlockWithAudience {
  slug: { _type: 'slug'; current: string };
  _type?: 'programmeCard';
  decorative?:
    | 'advanced-data-fellowship-topup'
    | 'ai-for-business-value'
    | 'ai-powered-productivity'
    | 'data-fellowship'
    | 'advanced-software-engineering'
    | 'software-engineering'
    | 'business-transformation'
    | 'transformative-leadership'
    | 'digital-business-accelerator'
    | 'project-management'
    | 'applied-data-engineering'
    | 'data-insights-for-business-decisions'
    | 'advance-data-fellowship'
    | 'technology-consulting';
  tag?: {
    _type: 'tag';
    tagText?: string;
    tagColour?: DecorativeColourType | SurfaceColourType;
  };
  title: string;
  duration?: Array<SanityKeyed<SanityBlock>>;
  programmeDescription?: Array<SanityKeyed<SanityBlock>>;
  audienceDescription?: Array<SanityKeyed<SanityBlock>>;
  qualification?: {
    _type: 'qualification';
    badgeType?: 'ribbon' | 'stamp';
    level?: Array<SanityKeyed<SanityBlock>>;
    qualificationTitle?: string;
  };
  audienceRef: SanityAudienceType;
  b2cAudience?: SanityProgrammeB2CType['b2cAudience'];
}

export const CourseCard = ({
  decorative,
  tag,
  title,
  duration,
  programmeDescription,
  audienceDescription,
  qualification,
  slug,
  b2cAudience,
  pageAudience,
  audienceRef,
  isAudienceSwitcherEnabled,
}: CourseCardProps) => {
  const { badgeType, level, qualificationTitle } = qualification || {};

  const backgroundColour = getBackgroundColor(getTokenColor(decorative));
  const decorativeImage = getDecorativeImage(decorative);
  const decorativePosition = getProgrammeCardDecorativePosition(decorative);

  const href = blockUrlWithAudience(
    `/courses/${slug.current}${b2cAudience ? `?b2cAudience=${b2cAudience}` : ''}`,
    audienceRef,
    pageAudience,
    false,
    isAudienceSwitcherEnabled,
  );

  return (
    <ProgrammeCardContainer href={href}>
      <HeadingLevel>
        <StyledSurface border="--border-warm-base" padding={false}>
          <CreativeContainer
            backgroundColour={backgroundColour}
            decorativePosition={decorativePosition}
          >
            <BlackOverlay />
            <DecorationImage
              src={`${decorativeImage.baseUrl}-mobile.svg`}
              alt=""
            />
            {tag?.tagText && (
              <BadgeContainer>
                <Badge bgColour={tag?.tagColour}>{tag?.tagText}</Badge>
              </BadgeContainer>
            )}
          </CreativeContainer>

          <ProgrammeContent spacing="--space-component-md">
            <ProgrammeTitle size="heading-md">
              <span>{title}</span>
            </ProgrammeTitle>
            {(duration || programmeDescription) && (
              <Stack spacing="--space-component-sm">
                {duration && (
                  <Duration>
                    <PortableText value={duration} />
                  </Duration>
                )}
                {programmeDescription && (
                  <ProgrammeDescription>
                    <PortableText value={programmeDescription} />
                  </ProgrammeDescription>
                )}
              </Stack>
            )}
            {audienceDescription && (
              <Stack spacing="--space-component-xs">
                <Paragraph size="label-sm">{`Who's it for`}</Paragraph>
                <AudienceDescription>
                  <PortableText value={audienceDescription} />
                </AudienceDescription>
              </Stack>
            )}
          </ProgrammeContent>
          <Divider orientation="horizontal" thickness="2px" />

          <QualificationContainer>
            {!!badgeType && (
              <QualificationBadgeContainer>
                {badgeType === 'ribbon' ? (
                  <img
                    src="/images/certificate-badge-ribbon-small.png"
                    alt=""
                  />
                ) : (
                  <img src="/images/certificate-badge-stamp-small.png" alt="" />
                )}
              </QualificationBadgeContainer>
            )}
            <Stack spacing="--space-component-xs">
              {level && (
                <LevelContainer>
                  <PortableText value={level} />
                </LevelContainer>
              )}
              {qualificationTitle && (
                <Paragraph size="body-sm">{qualificationTitle}</Paragraph>
              )}
            </Stack>
          </QualificationContainer>
        </StyledSurface>
      </HeadingLevel>
    </ProgrammeCardContainer>
  );
};

const CreativeContainer = styled.div<{
  backgroundColour?: string;
  decorativePosition?: { right: string; top: string };
}>`
  height: 120px;
  position: relative;
  background-color: ${({ backgroundColour }) => backgroundColour};
  border-radius: var(--radius-component-md);
  margin: var(--space-component-sm);
  background-position: right, top;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: background 0.3s;
  overflow: hidden;
  img {
    top: ${({ decorativePosition }) => decorativePosition?.top};
    right: ${({ decorativePosition }) => decorativePosition?.right};
  }
`;

const DecorationImage = styled.img`
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s;
`;

const BlackOverlay = styled.div`
  background: black;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s;
`;

const StyledSurface = styled(Surface)`
  border-radius: 19.6px;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
`;

const BadgeContainer = styled.div`
  position: absolute;
  right: 0;
  margin: var(--space-component-sm);
`;

const ProgrammeContent = styled(Stack)`
  position: relative;
  top: -28px;
  padding: 0 var(--space-component-xl);
  p {
    color: var(--text-warm-base);
  }
  flex: 1;
`;

const ProgrammeTitle = styled(Heading)`
  span {
    border-radius: var(--radius-component-xs);
    // this breaks the highlight at the end of the first line regardless of the width of the second line
    box-decoration-break: clone;
    // needed slightly more line height to allow the underline hover to not break for certain letters
    line-height: 35px;
    margin-left: -4px;
    padding: 4px 4px 0 4px;
    background: var(--background-subtle);
  }
`;

const Duration = styled.div`
  > p {
    ${bodySmall};
  }
`;
const ProgrammeDescription = styled.div`
  > p {
    ${bodyMedium};
  }
`;
const AudienceDescription = styled.div`
  > p {
    ${bodyMedium};
  }
`;

const QualificationContainer = styled.div`
  padding: var(--space-component-lg) var(--space-component-xl)
    var(--space-component-lg) var(--space-component-lg);
  display: flex;
  gap: var(--space-component-md);
  p {
    color: var(--text-warm-base);
  }
  b {
    color: var(--text-strong);
  }
`;

const QualificationBadgeContainer = styled.div`
  img {
    width: 40px;
    height: 48px;
  }
`;

const LevelContainer = styled.div`
  p {
    ${labelMedium};
    font-weight: var(--font-weight-regular);
  }
`;

const ProgrammeCardContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-grow: 1;
  :hover {
    ${ProgrammeTitle} {
      color: var(--text-action);
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-color: var(--border-strong);
      text-decoration-thickness: 1px;
      text-underline-offset: 10%;
    }
    ${ProgrammeContent} {
      p {
        color: var(--text-strong);
      }
    }

    ${DecorationImage} {
      transform: scale(1.02);
    }
    ${BlackOverlay} {
      opacity: 0.08;
    }
  }
  :focus-visible {
    outline-offset: 3px;
    outline-color: var(--border-action);
    outline-width: 2px;
    border-radius: 19.6px;
  }
  :active {
    ${StyledSurface} {
      transform: scale(0.98);
    }
  }
`;
