import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimatePresence, m } from 'framer-motion';

import {
  allItemsBehaviour,
  chevronStyle,
  getChevronColour,
  singleItemBehaviour,
  titleStyle,
} from '@/components/common/Accordion/utils';
import { screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';
import { Heading, HeadingLevel } from '@/components/common/MarkUp';
import { bodyMedium } from '@/styles/typography';

import type { AccordionHeadingPaddingOptions } from '@/components/common/Accordion/utils';
import type { IconSizesSet } from '@/components/common/Icon';
import type { BlockWithAudience } from '@/types/shared';
import type {
  AnimationEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';

export interface AccordionItemConfigProps extends BlockWithAudience {
  _key?: string;
  trackingTag?: { blockName: string; blockAction: string };

  // accordionBehaviour?: 'singleItem' to allow only 1 item to be opened at the same time
  accordionBehaviour?: 'singleItem' | 'allItems';

  // heading?: heading appears at top of the accordion item
  heading: string;

  // tabTitle?: title appears left to the heading
  tabTitle?: string;

  // isOpenedByDefault?: opened by default
  // Default: false
  isOpenedByDefault?: boolean;

  /* 
  Expected configuration to receive with 'singleItem' accordionBehaviour.
    - idx -> index of current accordionItem
    - openItemsState -> useState to control 1 open item at the time.
  */
  singleItemBehaviourConfig?: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  };

  timerAnimationConfig?: {
    // itemOrder?: used to calculate timer animation
    itemOrder?: number;

    // itemsAmount?: used to calculate timer animation
    itemsAmount?: number;

    // timer?: amount of seconds to run timer animation on each accordion item
    timer?: number;

    // pauseAnimation?: true to pause timer animation
    pauseAnimation?: boolean;

    // isInView?: true if accordion is in the view for timer animation
    isInView?: boolean;
  };

  chevronConfig?: {
    // isOldStyleChevron?: true to use FAQ-style (--icon-action) styling on chevron
    isOldStyleChevron?: boolean;

    // chevronSize?: specify chevron size
    chevronSize?: string | IconSizesSet;

    // isTransparentChevron?: chevron has no background colour
    isTransparentChevron?: boolean;
  };

  // isExpandable?: false to make accordionItem non-expandable
  isExpandable?: boolean;

  // children?: component to render the accordionItem
  children?: ReactNode;

  singleAccordionItem?: boolean;

  // headingPadding?: padding applied on a heading-button in open and closed states
  headingPadding?: {
    openState?: AccordionHeadingPaddingOptions;
    closedState?: AccordionHeadingPaddingOptions;
  };
}

export const AccordionItemConfigComponent = ({
  accordionBehaviour,
  isOpenedByDefault,
  singleItemBehaviourConfig,
  timerAnimationConfig,
  chevronConfig,
  headingPadding,
  children,
  heading,
  tabTitle,
  trackingTag,
  isExpandable = true,
  singleAccordionItem,
  _key,
}: AccordionItemConfigProps) => {
  // Configuration for the single item behaviour
  const idx = singleItemBehaviourConfig?.idx;
  const openItems = singleItemBehaviourConfig?.openItemsState?.[0];
  const memo = useMemo(() => openItems?.includes(idx), [idx, openItems]);

  // Configuration for the all items behaviour
  const [allItemsState, setAllItemsState] = useState(isOpenedByDefault);

  const handleToggle = () => {
    switch (accordionBehaviour) {
      case 'singleItem': {
        return singleItemBehaviour(singleItemBehaviourConfig, memo);
      }
      case 'allItems':
        return allItemsBehaviour([allItemsState, setAllItemsState]);
    }
  };

  const ItemComponent = (children: ReactNode) => {
    if (isExpandable) {
      return (
        <ItemButton
          type="button"
          aria-label={heading}
          aria-expanded={ariaExpanded}
          aria-controls={`item${_key}`}
          id={`accordion${_key}id`}
          className={
            trackingTag?.blockName &&
            `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
          }
          onClick={isOpen ? onClick : () => {}}
          headingPadding={
            isOpen ? headingPadding?.openState : headingPadding?.closedState
          }
        >
          {children}
        </ItemButton>
      );
    } else {
      return children;
    }
  };

  const { ariaExpanded, onClick, isOpen } = handleToggle();

  return (
    <ContentWrapper>
      {tabTitle && <TabTitleWrapper>{tabTitle}</TabTitleWrapper>}
      <OuterWrapper
        onClick={!isOpen && isExpandable ? onClick : () => {}}
        isOpen={isOpen}
        isExpandable={isExpandable}
      >
        <HeadingLevel>
          <ItemWrapper>
            {ItemComponent(
              <>
                {(!singleAccordionItem || isExpandable) && (
                  <TitleWrapper
                    styling={titleStyle(isExpandable, isOpen, headingPadding)}
                  >
                    {heading}
                  </TitleWrapper>
                )}
                {isExpandable && (
                  <AnimationWrapper>
                    {!!timerAnimationConfig?.timer &&
                      timerAnimationConfig?.isInView &&
                      TimerComponent(timerAnimationConfig, onClick)}
                    <ChevronWrapper
                      oldStyleChevron={chevronConfig?.isOldStyleChevron}
                      isOpen={isOpen}
                      isTransparentChevron={chevronConfig?.isTransparentChevron}
                    >
                      <Icon
                        icon="ChevronDown"
                        size={chevronConfig?.chevronSize}
                        colour={getChevronColour(
                          chevronConfig?.isTransparentChevron,
                          isOpen,
                          chevronConfig?.isOldStyleChevron,
                        )}
                      />
                    </ChevronWrapper>
                  </AnimationWrapper>
                )}
              </>,
            )}
          </ItemWrapper>
        </HeadingLevel>
        <AnimatePresence initial={false}>
          {isOpen && (
            <BodyWrapper
              id={`item${_key}`}
              aria-labelledby={`accordion${_key}id`}
              key="content"
              initial="closed"
              animate={isOpen ? 'open' : 'closed'}
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                  height: 'auto',
                },
                closed: {
                  opacity: 0,
                  height: 0,
                },
              }}
              transition={{
                duration: 0.5,
                ease: [0.04, 0.62, 0.23, 0.98],
              }}
            >
              {children}
            </BodyWrapper>
          )}
        </AnimatePresence>
      </OuterWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.li`
  display: flex;
`;

const TabTitleWrapper = styled.span`
  display: none;

  ${screen.sm} {
    display: block;
    ${bodyMedium}
    color: var(--text-warm-subtle);
    min-width: var(--spacing-900);
    width: var(--spacing-900);
  }
`;

export const OuterWrapper = styled.div<{
  isOpen: boolean;
  isExpandable: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isOpen, isExpandable }) =>
    !isOpen &&
    isExpandable &&
    css`
      &:hover {
        cursor: pointer;
        ${TitleWrapper} {
          color: var(--text-strong);
          text-decoration: underline;
          text-underline-offset: 3px;
          text-decoration-thickness: 1px;
          text-decoration-color: var(--border-strong);
        }
      }

      &:focus {
        text-decoration: none;
        border-radius: var(--radius-component-sm);
        border: 2px solid var(--border-action, #4a5ff7);
        color: var(--text-strong);
      }
    `}
`;

const ItemWrapper = styled(Heading)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.span<{
  styling: string;
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ styling }) => styling}
`;

const AnimationWrapper = styled.div`
  position: relative;
  align-self: flex-start;
`;

const ChevronWrapper = styled.div<{
  isOpen: boolean;
  oldStyleChevron: boolean;
  isTransparentChevron?: boolean;
}>`
  border-radius: 50%;

  ${({ oldStyleChevron, isOpen, isTransparentChevron }) =>
    chevronStyle(isOpen, oldStyleChevron, isTransparentChevron)}

  transition: background-color 0.6s;

  svg {
    transition: transform 0.6s;
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  }
`;

const BodyWrapper = styled(m.div)`
  overflow: hidden;
`;

const ItemButton = styled.button<{
  headingPadding: AccordionHeadingPaddingOptions;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  ${({ headingPadding }) =>
    headingPadding
      ? css`
          padding-top: ${headingPadding.paddingTop};
          padding-right: ${headingPadding.paddingRight};
          padding-left: ${headingPadding.paddingLeft};
          padding-bottom: 0;
        `
      : css`
          padding: 0;
        `}
  margin: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  color: inherit;
  cursor: pointer;
  font: inherit;
`;

const TimerComponent = (
  timerAnimationConfig: AccordionItemConfigProps['timerAnimationConfig'],
  onClick: AnimationEventHandler,
) => (
  <TimerAnimation
    timerAnimationConfig={timerAnimationConfig}
    onAnimationStart={onClick}
    onAnimationIteration={onClick}
  >
    <Icon icon="Timer" size="40px" />
  </TimerAnimation>
);

const TimerAnimation = styled.div<{
  timerAnimationConfig?: AccordionItemConfigProps['timerAnimationConfig'];
}>`
  position: absolute;
  top: -6px;
  left: -6px;
  transform: scaleY(-1);

  svg {
    @keyframes dash {
      0% {
        stroke-dashoffset: ${Math.PI * 36};
      }
      ${({ timerAnimationConfig: { itemsAmount } }) => `${100 / itemsAmount}% 
      {
        stroke-dashoffset: 0;
      }`}

      100% {
        stroke-dashoffset: ${Math.PI * 36};
      }
    }
    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      5%,
      ${({ timerAnimationConfig: { itemsAmount } }) =>
          `${100 / itemsAmount}% {
       opacity: 1;
       } 
       ${100 / itemsAmount + 1}% {
       opacity: 0;
       }`}
        100% {
        opacity: 0;
      }
    }
    opacity: 0;
    // First value - length, Second - delay
    animation: fadeInOut
      ${({ timerAnimationConfig: { timer, itemsAmount, itemOrder } }) =>
        `${timer * itemsAmount}s ${itemOrder * timer}s`}
      linear infinite;

    animation-play-state: ${({ timerAnimationConfig: { pauseAnimation } }) =>
      pauseAnimation ? 'paused' : 'running'};

    .blueCircle {
      stroke-dasharray: ${Math.PI * 36};
      stroke-dashoffset: ${Math.PI * 36};
      animation: dash
        ${({ timerAnimationConfig: { timer, itemsAmount, itemOrder } }) =>
          `${timer * itemsAmount}s ${itemOrder * timer}s`}
        linear infinite;
      animation-play-state: ${({ timerAnimationConfig: { pauseAnimation } }) =>
        pauseAnimation ? 'paused' : 'running'};
    }
  }
`;
