import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import Link from 'next/link';

import { Icon } from '@/components/common/Icon';
import { isLightBackground } from '@/lib/colourUtils';
import { labelLarge } from '@/styles/typography';

import type {
  BackgroundColourTokenType,
  DecorativeColourTokenType,
  SurfaceColourTokenType,
  TextColourTokenType,
} from '@/types/colours';

type LinkProps = React.ComponentProps<typeof Link>;

interface BaseLinkProps extends LinkProps {
  trackingTag?: { blockName: string; blockAction: string };
  backgroundColour?: BackgroundColourTokenType | SurfaceColourTokenType;
  textColour?: TextColourTokenType | DecorativeColourTokenType;
  iconPosition?: 'left' | 'right';
  showVisited?: boolean;
}

const BaseLink = ({
  trackingTag,
  backgroundColour,
  textColour,
  iconPosition = null,
  showVisited = false,
  children,
  ...props
}: BaseLinkProps) => {
  return (
    <LinkContainer
      className={
        trackingTag?.blockName &&
        `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
      }
      backgroundColour={backgroundColour}
      textColour={
        textColour ? textColour : getTextColour(backgroundColour, 'default')
      }
      visitedColour={getTextColour(backgroundColour, 'visited')}
      iconPosition={iconPosition}
      showVisited={showVisited}
      {...props}
    >
      {iconPosition === 'left' && (
        <StyledIcon icon="ArrowRight" size="0.7em" aria-hidden="true" />
      )}

      {children}

      {iconPosition === 'right' && (
        <StyledIcon icon="ArrowRight" size="0.7em" aria-hidden="true" />
      )}
    </LinkContainer>
  );
};

const getTextColour = <
  T extends BackgroundColourTokenType | SurfaceColourTokenType,
>(
  backgroundColour: T,
  state: 'default' | 'visited',
) => {
  if (isLightBackground(backgroundColour) || !backgroundColour) {
    return state === 'visited' ? '--text-inverse-action' : '--text-action';
  } else {
    return state === 'visited' ? '--text-eclipse' : '--text-inverse-strong';
  }
};

const StyledIcon = styled(Icon)`
  margin: 0 var(--spacing-xxx-small);
`;

const LinkContainer = styled(Link, { shouldForwardProp: isPropValid })<{
  textColour: TextColourTokenType | DecorativeColourTokenType;
  backgroundColour: BackgroundColourTokenType | SurfaceColourTokenType;
  visitedColour: TextColourTokenType | DecorativeColourTokenType;
  iconPosition: 'left' | 'right';
  showVisited: boolean;
}>`
  ${labelLarge}
  color: ${({ textColour }) => `var(${textColour})`};
  width: fit-content;
  text-decoration: none;

  ${({ iconPosition }) =>
    iconPosition &&
    `
    display: inline-flex;
    align-items: baseline;
  `}

  &:hover {
    text-decoration-color: ${({ textColour }) => `var(${textColour})`};
    text-decoration-thickness: 1px;
    text-decoration-line: underline;
    text-underline-offset: 10%;
  }

  &:focus-visible {
    text-decoration: none;
    outline: 1px solid ${({ textColour }) => `var(${textColour})`};
    outline-offset: var(--spacing-50);
    border-radius: var(--radius-xxs);
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: var(--opacity-disabled);
    pointer-events: none;
  }

  ${({ showVisited, visitedColour }) =>
    showVisited &&
    `
      &:visited {
        color: var(${visitedColour});

        ${StyledIcon} {
          color: var(${visitedColour});
        }
      }
    `}
`;

export default BaseLink;
