import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { TextColourType } from '@/types/colours';
import type { CSSProperties } from 'react';

interface HighlightAnnotationProps {
  children: React.ReactNode;
  colour: TextColourType;
  style: 'straight' | 'exaggerate' | 'reduce';
}

export const HighlightAnnotation = ({
  children,
  colour,
  style,
}: HighlightAnnotationProps) => {
  return (
    <Span
      highlightStyle={style}
      style={
        {
          '--highlight-colour': `var(${colour?.token}, --decorative-eclipse)`,
        } as CSSProperties
      }
    >
      {children}
    </Span>
  );
};

const pillStyle = css`
  border-radius: var(--radius-50);

  background-color: var(--highlight-colour);
  outline: 0.12em solid var(--highlight-colour);
  outline-offset: 0px;

  white-space: break-spaces;
  line-height: 1.4;
  color: var(--text-strong);
`;

const Span = styled.span<{
  highlightStyle: HighlightAnnotationProps['style'];
}>`
  ${(props) => {
    switch (props.highlightStyle) {
      case 'straight':
        return css`
          ${pillStyle}
        `;

      case 'exaggerate':
        return css`
          ${pillStyle}

          font-size: 1.4em;
        `;

      case 'reduce':
        return css`
          color: var(--text-warm-base);
          font-weight: var(--font-weight-regular);
        `;

      default:
        break;
    }
  }}
`;
