import styled from '@emotion/styled';

import {
  getBadgeMarginStyles,
  getBadgeSizesStyles,
} from '@/components/common/Badge/badgeUtils';
import { getTextColour } from '@/lib/colourUtils';
import { labelLarge } from '@/styles/typography';

import type {
  DecorativeColourTokenType,
  DecorativeColourType,
  SurfaceColourTokenType,
  SurfaceColourType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';

export type ResponsiveSet = {
  base?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

export type BadgeSizes = 'lg' | 'md' | 'sm';

export interface BadgeProps {
  bgColour?: DecorativeColourType | SurfaceColourType;
  textColour?: TextColourTokenType;
  children: string | ReactNode;
  margin?: string | ResponsiveSet;
  size?: BadgeSizes | ResponsiveSet;
}

export const Badge = ({
  children,
  bgColour: { token: bgColourToken } = {
    title: 'eclipse / eclipse-200',
    value: 'rgb(221, 252, 157)',
    token: '--decorative-eclipse',
  },
  textColour,
  margin = '0 0 var(--spacing-small) 0',
  size = 'lg',
}: BadgeProps) => {
  return (
    <BadgeContainer
      bgColour={bgColourToken}
      textColour={textColour || getTextColour(bgColourToken)}
      margin={margin}
      size={size}
    >
      {children}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.span<{
  bgColour: DecorativeColourTokenType | SurfaceColourTokenType;
  textColour: TextColourTokenType;
  margin: string | ResponsiveSet;
  size: BadgeSizes | ResponsiveSet;
}>`
  ${labelLarge}
  display: inline-block;
  width: fit-content;
  padding: var(--spacing-xxx-small) var(--spacing-xx-small);
  border-radius: var(--radius-100);
  background-color: ${(props) => `var(${props.bgColour})`};
  color: ${(props) =>
    (props.textColour && `var(${props.textColour})`) ||
    'var(--text-inverse-strong)'};
  ${({ margin }) => margin && getBadgeMarginStyles(margin)};
  ${({ size }) => size && getBadgeSizesStyles(size)};
`;
