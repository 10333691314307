import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Accordion } from '@/components/common/Accordion';
import {
  OuterWrapper,
  TitleWrapper,
} from '@/components/common/Accordion/AccordionItemConfig';
import { breakpoints, screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Image } from '@/components/common/image';
import { HeadingLevel } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { PortableText } from '@/components/common/utils';
import { bodyMedium, labelLarge } from '@/styles/typography';

import type {
  AccordionProps,
  GeneralAccordionItemProps,
} from '@/components/common/Accordion';
import type { BlockWithAudience, HotspotImage } from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface DefaultAccordionItemProps
  extends BlockWithAudience,
    GeneralAccordionItemProps {
  _key?: string;

  // body?: appears below heading of the accordion item
  body?: Array<SanityKeyed<SanityBlock>>;

  // itemImage?: individual image of an accordion item
  itemImage?: HotspotImage & { alternateText?: string };

  // primaryImage?: primary image of an accordion
  primaryImage?: HotspotImage & { alternateText?: string };

  // accordionItemPadding?: additional padding in accordion items to match the height of the longest item
  accordionItemPadding?: number;

  // accordion?: inner accordion items
  accordion?: DefaultAccordionItemProps[];
}

export const DefaultAccordionItem = ({
  _key,
  body,
  pageAudience,
  isAudienceSwitcherEnabled,
  itemImage,
  primaryImage,
  accordionItemPadding,
  accordion,
}: DefaultAccordionItemProps) => {
  return (
    <>
      {body && (
        <Description
          accordionItemPadding={accordionItemPadding}
          pointColour="--icon-warm-base"
          paragraphFontSize="body-md"
          iconSize={16}
        >
          <PortableText
            value={body}
            audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
          />
        </Description>
      )}
      {accordion && (
        <InnerAccordionComponent
          accordionItems={accordion}
          accordionBehaviour="allItems"
          chevronSize="12px"
          isTransparentChevron={true}
          className={`inner_${_key}`}
          backgroundColour={'--background-warm-base'}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled
          headingPadding={{
            openState: {
              paddingTop: '16px',
              paddingLeft: '0',
              paddingRight: '0',
            },
            closedState: {
              paddingTop: '16px',
              paddingLeft: '0',
              paddingRight: '0',
            },
          }}
        />
      )}
      {MobileImageComponent(primaryImage, itemImage)}
    </>
  );
};

const InnerAccordionComponent = (accordionProps: AccordionProps) => (
  <HeadingLevel>
    <InnerAccordionWrapper>
      <Accordion {...accordionProps} />
    </InnerAccordionWrapper>
  </HeadingLevel>
);

const Description = styled(BulletPoints)<{
  accordionItemPadding: number;
}>`
  padding-top: var(--space-component-md);

  > ul {
    padding-left: var(--space-component-xs);
    li {
      margin-bottom: 0;
    }
  }
  color: var(--text-warm-base);
  > p {
    ${({ accordionItemPadding }) =>
      !!accordionItemPadding &&
      css`
        padding-bottom: ${accordionItemPadding}px;
      `}
    ${bodyMedium}
    padding-right: calc(var(--space-component-md) + 28px);
  }
`;

const InnerAccordionWrapper = styled.div`
  padding-top: var(--space-component-xl);

  > ul {
    border-radius: var(--radius-component-md);
    padding: 0 var(--space-component-lg);
    > li:not(:last-child) {
      > ${OuterWrapper} {
        border-bottom: 1px solid var(--border-warm-subtle);
      }
    }
    > li {
      gap: var(--space-component-md);
      > span {
        padding: var(--space-component-lg) 0;
      }
      > div {
        padding-bottom: var(--space-component-lg);
      }
    }
    ${TitleWrapper} {
      ${labelLarge}
    }
    ${OuterWrapper} {
      ${Description} {
        padding-top: var(--space-component-lg);
        ul + p {
          padding: var(--space-component-lg) 0 0;
        }
        p:only-child {
          padding: 0;
        }
      }
    }
  }
`;

const ImageWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    display: block;

    ${screen.md} {
      padding: var(--space-component-xxl) 0 var(--space-component-sm);
    }
    padding-top: var(--space-component-xl);

    & img {
      height: 240px;
      border-radius: var(--radius-component-lg);
    }
  }
`;

const MobileImageComponent = (
  primaryImage: DefaultAccordionItemProps['primaryImage'],
  itemImage: DefaultAccordionItemProps['itemImage'],
) => {
  const imageChosen = (): HotspotImage & { alternateText?: string } => {
    if (primaryImage?.asset) {
      return primaryImage;
    } else if (itemImage?.asset) {
      return itemImage;
    }
  };

  return (
    imageChosen() && (
      <ImageWrapper>
        <Surface
          surfaceColour="--surface-warm-base"
          borderRadius="--radius-component-md"
          padding={false}
        >
          <Image
            {...imageChosen()}
            alt={imageChosen()?.alternateText}
            objectFit="cover"
          />
        </Surface>
      </ImageWrapper>
    )
  );
};
